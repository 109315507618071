<script>
  import HiddenElement from './../../../blank/templates/elements/HiddenElement.vue'

  export default {
    name: 'HiddenElement',
    render: HiddenElement.render,
    data() {
      return {
        merge: true,
        defaultClasses: {}
      }
    }
  }
</script>

<style lang="scss">
</style>